import React from 'react';
import { Button, Result } from 'antd'

const NotFound = (props) => {
  return <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary" onClick={() => window.location = window.location.origin}>Back Home</Button>}
  />
};

export default NotFound;
