import react from 'react';
import { isAdmin, isSeller } from '../common/env';

const RestrictedView = ({role, children, ...props}) => {
    if (role === 'admin') {
        return isAdmin() && children
    }
    else if (role === 'seller') {
        return (isAdmin() | isSeller) && children
    }
    else return null
}

export default RestrictedView