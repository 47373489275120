import React, { useEffect, useState } from 'react';
import { Tree, Input, Space, Slider, Divider } from 'antd';
import Button from '../components/Button';
import {
  useSearchBox,
  useRefinementList,
  useClearRefinements,
  useInstantSearch,
  useRange,
} from 'react-instantsearch-hooks-web';
import { SyncOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import RestrictedView from './RestrictedView';

const { Search } = Input;

const App = (props) => {
  const { refine } = useSearchBox();
  const { items, refine: checkRefine } = useRefinementList({
    attribute: '농가명',
    limit: 20,
    sortBy: ['count'],
  });
  const { items: typeItems, refine: checkTypeRefine } = useRefinementList({
    attribute: '수종',
    limit: 1000,
    sortBy: ['count'],
  });
  const { refine: clear } = useClearRefinements();

  const location = useLocation();
  const { start, range, refine: rangeRefine } = useRange({ attribute: 'H' });
  const [minTreeHeight, setMinTreeHeight] = useState(0);
  const [maxTreeHeight, setMaxTreeHeight] = useState(20);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [checkedTypeKeys, setCheckedTypeKeys] = useState([]);
  const [selectedTypeKeys, setSelectedTypeKeys] = useState([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [sliderValue, setSliderValue] = useState([0, 0]);
  const { refresh } = useInstantSearch();
  const marks = {};
  marks[minTreeHeight] = minTreeHeight + `m`;
  marks[maxTreeHeight] = maxTreeHeight + `m`;
  useEffect(() => {
    if (
      checkedKeys.length > 0 ||
      checkedTypeKeys.length > 0 ||
      start[0] !== -Infinity ||
      start[1] !== Infinity
    )
      setHasFilter(true);
    else setHasFilter(false);
  }, [checkedKeys, checkedTypeKeys, start]);
  useEffect(() => {
    setTimeout(() => {
      setSliderValue([minTreeHeight, maxTreeHeight]);
    }, 500);
  }, []);
  useEffect(() => {
    setMinTreeHeight(range.min);
    setMaxTreeHeight(range.max);
  }, [range]);
  useEffect(() => {
    setTimeout(() => {
      refresh();
    }, 1000);
  }, [location]);
  const onCheck = (checkedKeysValue, e) => {
    console.log('onCheck', checkedKeysValue);
    const { checked, node } = e;
    const { key } = node;
    checkRefine(items[key].value);
    setCheckedKeys(checkedKeysValue);
  };
  const onTypeCheck = (checkedKeysValue, e) => {
    console.log('onCheck', checkedKeysValue);
    const { checked, node } = e;
    const { key } = node;
    checkTypeRefine(typeItems[key].value);
    setCheckedTypeKeys(checkedKeysValue);
  };
  const onSelect = (selectedKeysValue, info) => {
    console.log('onSelect', info);
    setSelectedKeys(selectedKeysValue);
  };
  const onTypeSelect = (selectedKeysValue, info) => {
    console.log('onSelect', info);
    setSelectedTypeKeys(selectedKeysValue);
  };
  const onAfterChange = (value) => {
    setSliderValue(value);
    rangeRefine(value);
  };
  const resetFilter = () => {
    clear();
    setCheckedKeys([]);
    setCheckedTypeKeys([]);
    setSliderValue([0, 20]);
  };
  return (
    <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ fontSize: 16, fontWeight: '600' }}>Filters</div>
          <Button
            type={hasFilter ? 'primary' : 'default'}
            style={{
              marginLeft: 10,
              height: 24,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
              width: 54,
            }}
            onClick={resetFilter}
          >
            초기화
          </Button>
        </div>
        <Button
          style={{
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          icon={<SyncOutlined />}
          onClick={refresh}
        />
      </div>
      <div>{`총 검색건수: ${props.results.nbHits}`}</div>
        <RestrictedView>
          <div>
            <Divider orientation='left' orientationMargin='0'>
              <div style={{ fontSize: 14, fontWeight: '600' }}>농장명</div>
            </Divider>
            <Search
              onChange={(e) => {
                refine(e.target.value);
              }}
            />
          </div>
          <Tree
            disabled={checkedTypeKeys.length > 0}
            checkable
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            treeData={items.map((item, key) => ({ title: item.value, key }))}
            // treeData={treeData}
          />
          </RestrictedView>
      
      <div>
        <Divider orientation='left' orientationMargin='0'>
          <div style={{ fontSize: 14, fontWeight: '600' }}>높이 (m)</div>
        </Divider>
        {/* 이건 안해도 될듯? */}
        <Input.Group compact>
          <Input
            style={{ width: 86, textAlign: 'center' }}
            placeholder='최소'
          />
          <Input
            className='site-input-split'
            style={{
              width: 30,
              borderLeft: 0,
              borderRight: 0,
              pointerEvents: 'none',
            }}
            placeholder='~'
            disabled
          />
          <Input
            className='site-input-right'
            style={{
              width: 86,
              textAlign: 'center',
            }}
            placeholder='최대'
          />
        </Input.Group>
        {/* <Search
          onChange={(e) => {
            refine(e.target.value);
          }}
        /> */}
      </div>
      <Slider
        range
        step={maxTreeHeight - minTreeHeight > 2 ? 0.5 : 0.1}
        marks={marks}
        min={minTreeHeight}
        max={maxTreeHeight}
        defaultValue={[minTreeHeight, maxTreeHeight]}
        value={sliderValue}
        onAfterChange={onAfterChange}
        onChange={(value) => setSliderValue(value)}
      />
      <div>
        <Divider orientation='left' orientationMargin='0'>
          <div style={{ fontSize: 14, fontWeight: '600' }}>수종</div>
        </Divider>
        <div>{`총 검색수종 수: ${typeItems.length}`}</div>
        <Search
          onChange={(e) => {
            refine(e.target.value);
          }}
        />
      </div>
      <Tree
        checkable
        onCheck={onTypeCheck}
        checkedKeys={checkedTypeKeys}
        onSelect={onTypeSelect}
        selectedKeys={selectedTypeKeys}
        treeData={typeItems.map((item, key) => ({ title: item.value, key }))}
        // treeData={treeData}
      />
    </Space>
  );
};
export default App;
