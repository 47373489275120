import React, { useState, useEffect } from 'react';
import { List, Layout, Menu, Skeleton, Button, Modal } from 'antd';
import TreeCard from '../components/TreeCard';
import TreeFilter from '../components/TreeFilter';
import { UserOutlined } from '@ant-design/icons';

import trees from '../data/dummy';
import { InstantSearch } from '../common/algoliasearch';
import { useHits, useInfiniteHits } from 'react-instantsearch-hooks-web';
import InfiniteScroll from 'react-infinite-scroll-component';

import TreeDetail from './TreeDetail';

const { Sider } = Layout;

const items2 = [
  {
    key: 'sub1',
    icon: <UserOutlined />,
    label: 'Type',
    items: [
      {
        key: 'subkey1',
        label: 'R50',
      },
      {
        key: 'subkey2',
        label: 'R40',
      },
      {
        key: 'subkey3',
        label: 'R25',
      },
    ],
  },
];

const TreeList = () => {
  const { hits, results, showMore, isLastPage } = useInfiniteHits();
  const [margin, setMargin] = useState(24);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [treeObject, setTreeObject] = useState();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onClickCard = (tree) => {
    setTreeObject(tree);
    showModal();
  }

  return (
    <Layout style={{ background: 'white' }}>
      <Sider
        breakpoint='md'
        collapsedWidth={0}
        width={200}
        style={{ background: 'white', marginRight: margin }}
        onBreakpoint={(broken) => setMargin(broken ? 0 : 24)}
        zeroWidthTriggerStyle={{
          top: 0,
          left: -24,
        }}
      >
        <TreeFilter hits={hits} results={results} />
      </Sider>
      <InfiniteScroll
        style={{ paddingRight: 8 }}
        dataLength={hits.length}
        next={() => {
          setTimeout(() => {
            showMore();
          }, 1000);
        }}
        hasMore={!isLastPage}
        loader={
          <>
            <Skeleton active />
            <Button onClick={showMore}>more</Button>
          </>
        }
      >
        <List
          grid={{
            gutter: 16,
            xs: 2,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 6, // unlimited
          }}
          dataSource={[...hits]}
          renderItem={(item) => (
            <List.Item style={{ padding: 0 }}>
              <TreeCard tree={item} onClick={(tree) => {onClickCard(tree)}} />
            </List.Item>
          )}
        />
      </InfiniteScroll>
      <Modal
        title={treeObject?.수종}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'80vw'}
      >
        { treeObject ? <TreeDetail tree={treeObject}/> : null }
      </Modal>
    </Layout>
  );
};

const TreeListWrapper = () => {
  return (
    <InstantSearch indexName='tree_index'>
      <TreeList />
    </InstantSearch>
  );
};

export default TreeListWrapper;
