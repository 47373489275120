import React, { useEffect, useState } from 'react';
import { Card, Typography, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';

// import {
//   EditOutlined,
//   EllipsisOutlined,
//   SettingOutlined,
// } from '@ant-design/icons';
import trees from '../data/dummy';
import RestrictedView from './RestrictedView';

// const { Title } = Typography;
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const TreeCard = (props) => {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(getWindowDimensions());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowDimensions());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const { tree, onClick } = props;
  return (
    <Card
      bordered={false}
      cover={
        <img
          alt='example'
          src={tree.촬영물 ? tree.촬영물[0]?.replace('upload/', 'upload/q_40/') : trees[1].thumbnail}
          style={{
            objectFit: 'cover',
            height: (windowSize.width > 1600 || windowSize.width < 576) && '20vh',
            maxWidth: 320,
            maxHeight: windowSize.width > 1600 ? 400 : windowSize.width > 576 ? 300 : 480,
            minHeight: windowSize.width > 1600 ? 360 : windowSize.width > 576 ? 300 : 0,
          }}
        />
      }
      // actions={[
      //   <SettingOutlined key='setting' />,
      //   <EditOutlined key='edit' />,
      //   <EllipsisOutlined key='ellipsis' />,
      // ]}
      style={{}}
      hoverable
      bodyStyle={{ padding: 12 }}
      onClick={() => onClick(tree)}
    >
      {/* <Title level={5}>{tree.name}</Title> */}
      <div style={{ fontSize: 16, fontWeight: 500 }}>{tree.수종}</div>
      <div style={{ marginTop: 8 }}>
        {tree.R && <Tag color='magenta'>R {tree.R}</Tag>}
        {tree.H && <Tag color='volcano'>H {tree.H}</Tag>}
        {tree.B && <Tag color='geekblue'>B {tree.B}</Tag>}
        {tree.W && <Tag color='purple'>W {tree.W}</Tag>}
        {tree.P && <Tag color='orange'>P {tree.P}</Tag>}
      </div>
      <RestrictedView>
      <div>{`${tree.농가명} ${tree.농가넘버 ? `(${tree.농가넘버})` : ''}`}</div>
      <div>{`${tree.필지주소} ${
          tree.필지넘버 ? `(${tree.필지넘버})` : ''
        }`}</div>
        <div style={{ marginTop: 8 }}>
        {tree.데이터수집인?.map((name, index) => (
          <Tag color='green' key={index}>
            {name}
          </Tag>
        ))}
      </div>
      </RestrictedView>
    </Card>
  );
};

export default TreeCard;
